import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        let colonne = document.getElementsByClassName('-colonne-relation');

        if (colonne.length > 1){
            colonne[1].parentNode.parentNode.classList.add('b-relation_style_colonne');
        }
    }
}
