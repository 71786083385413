import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                open: 'open',
                select: 'select',
                delete: 'delete',
                //reset: 'reset',
                submit: 'submit',
                update: 'update',
                sublink: 'toggleSublink'
            },
            change: {
                //select: 'select',
                qte: 'qte',
            }
        }

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
    }

    open(e) {
        const html = document.querySelector('.c-nav_menuSlide');
        if (html.classList.contains('has-menuOpen')){
            html.classList.remove('has-menuOpen');
            document.getElementsByTagName('main')[0].style.display = 'flex';
            document.getElementsByTagName('footer')[0].style.display = 'flex';
            e.currentTarget.childNodes[1].childNodes[3].style.visibility = 'initial';
            e.currentTarget.childNodes[1].childNodes[1].style.transform = 'rotate(0)';
            e.currentTarget.childNodes[1].childNodes[5].style.transform = 'rotate(0)';
        } else{
            html.classList.add('has-menuOpen');
            document.getElementsByTagName('main')[0].style.display = 'none';
            document.getElementsByTagName('footer')[0].style.display = 'none';
            e.currentTarget.childNodes[1].childNodes[3].style.visibility = 'hidden';
            e.currentTarget.childNodes[1].childNodes[1].style.transform = 'rotate(45deg)';
            e.currentTarget.childNodes[1].childNodes[1].style.transformOrigin = 'left';
            e.currentTarget.childNodes[1].childNodes[5].style.transform = 'rotate(-45deg)';
            e.currentTarget.childNodes[1].childNodes[5].style.transformOrigin = 'left';
        }
    }

    select(e) {
        const target = e.currentTarget;
        const btn = this.parent('btn', target);
        const row = this.parent('row', target);
        const btns = row.querySelectorAll('.c-widget-menu_menu-btn');
        btns.forEach(element => element.classList.remove('is-active'));
        btn.classList.add('is-active');
    }

    delete(e) {
        const target = e.currentTarget;
        const container = jQuery(target).parents('.menu-widget-item');
        const form = jQuery(target).parent('form');
        const main_form_container = jQuery(target).parents('.c-tab_item').find('form');

        jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: form.serialize(),
            success: function( response ){
                container.remove();

                $(main_form_container).find('.default').removeClass('is-active');
                $(main_form_container)[0].reset();
            },
        });
    }

    toggleSublink(e){
        // if (e.target.parentNode.parentNode.childNodes[3].classList.contains('sub-open')){
        //     e.target.parentNode.parentNode.childNodes[3].classList.remove('sub-open');
        //     e.target.parentNode.parentNode.childNodes[1].childNodes[1].childNodes[1].classList.remove('rotate');
        // } else{
        //     e.target.parentNode.parentNode.childNodes[3].classList.add('sub-open');
        //     e.target.parentNode.parentNode.childNodes[1].childNodes[1].childNodes[1].classList.add('rotate');
        // }
        let el = e.currentTarget;
        let parent = $(el).parents('.c-nav_link_component');
        let target = $(parent).find('.c-nav_primary_sublink');
        if($(target).attr('class').indexOf('sub-open') != -1) {
            $(target).removeClass('sub-open');
            $(el).find('.c-nav_primary_plus').removeClass('rotate');
            $(el).removeClass('is-active');
        }else {
            $('.c-nav_link_component .c-nav_primary_sublink').removeClass('sub-open');
            $('.c-nav_link_component .c-nav_primary_link').removeClass('is-active');
            $('.c-nav_link_component .c-nav_primary_plus').removeClass('rotate');
            $(target).addClass('sub-open');
            $(el).find('.c-nav_primary_plus').addClass('rotate');
            $(el).addClass('is-active');
        }
    }

    /*select(e) {
      const target = e.currentTarget;
      const btn = this.parent('btn', target);
      const row = this.parent('row', target);
      const btns = row.querySelectorAll('.c-widget-menu_menu-btn');
      btns.forEach(element => element.classList.remove('is-active'));
      btn.classList.add('is-active');

      const form = this.parent('form', target);
      const menu_id = $(form).find('input[name="menu_id"]').val();

      jQuery.ajax({
        url: this.ajaxurl,
        type: 'POST',
        data: jQuery(form).serialize(),
        success: function( response ){
          $('#'+response.menu_id).remove();
          $(form).parent('.c-tab_item').find('.c-widget-menu_item-cart').prepend(response.menu);
        },
      });
    }*/

    qte(e) {
        const target = e.currentTarget;
        const form = jQuery(target).parent('form');

        jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: form.serialize(),
            success: function( response ){

            },
        });
    }

    /*reset(e) {
      const target = e.currentTarget;
      const form = jQuery(target).parents('.c-tab_item').find('form');
      form[0].reset();

      const btns = form.querySelectorAll('.c-widget-menu_menu-btn');
      btns.forEach(element => element.classList.remove('is-active'));
    }*/

    submit(e) {
        const target = e.currentTarget;
        const form = this.parent('form', target);
        var error = false;

        if(!jQuery(form).find('input[name="plats"]').is(":checked")) {
            error = true;
        }

        if(!error) {
            jQuery(form).find('.msg').html('');
            jQuery.ajax({
              url: this.ajaxurl,
              type: 'POST',
              data: jQuery(form).serialize(),
              success: function( response ){
                $('#'+response.menu_id).remove();
                $(form).parent('.c-tab_item').find('.c-widget-menu_item-cart').append(response.menu);
                $(form).find('.default').removeClass('is-active');
                $(form)[0].reset();
                $(form).find('input[name="menu_id"]').val(response.new_menu_id);
              },
            });
        }else {
            jQuery(form).find('.msg').html('Veuillez faire votre choix !');
        }
    }

    update(e) {
        const target = e.currentTarget;
        const id = target.getAttribute('id');
        const qte = jQuery(target).find('input[name="qte"]').val();
        const form = jQuery(target).parents('.c-tab_item').find('form');
        const menu_text = jQuery(target).find('.c-heading').text();

        const items = this.$('update');
        items.forEach(element => element.classList.remove('is-active'));

        target.classList.add('is-active');

        form.find('input[name="menu_id"]').val(id);
        form.find('input[name="quantite"]').val(qte);
        form.find('.main-title').text(menu_text);
    }
}
