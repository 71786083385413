import { module } from 'modujs';

export default class extends module {
  constructor(m) {
      super(m);

      this.events = {
        click: {
          header: 'toggleSection',
          onglet: 'toggleOnglet',
          timeline: 'toggleTimeline',
        }
      }
  }

  init() {
    if (this.getData('open')) {
      this.$('section')[0].classList.add('is-open');
    }

    if (document.getElementsByClassName('b-onglet_nav_h1').length > 0){
      document.getElementsByClassName('b-onglet_nav_h1')[0].classList.add('clicked');
      document.getElementsByClassName('b-timeline_date_container')[0].classList.add('b-timeline_active');
    }
  }

  toggleSection(e) {
    const target = e.currentTarget;
    const section = this.parent('section', target);
    if (section.classList.contains('is-open')) {
      section.classList.remove('is-open');
    } else {
      const sections = this.$('section');
      sections.forEach(element => element.classList.remove('is-open')); // close section opened
      section.classList.add('is-open');
      if(this.getData('scrollto') == 'true') {
        this.call('scrollTo', section, 'Scroll'); // scrollTo section
      }
    }
    this.call('update', false, 'Scroll'); // update Locomotive Scroll
  }

  toggleOnglet(e){
    let text = document.getElementsByClassName('b-onglet_text');
    let id = Number(e.target.id.split('-')[1]) * -100;

    for (let i = 0; i < text.length; i++){
      text[i].style.transform= `translateX(${id}%)`;
      if (document.getElementsByClassName('clicked')[0]) document.getElementsByClassName('clicked')[0].classList.remove('clicked');
      e.target.classList.add('clicked')
    }
  }

  toggleTimeline(e){
    let article = document.getElementsByClassName('b-timeline_article');
    let index = Number(e.target.id.split('-')[1]) * -100;

    for (let u = 0; u < article.length; u++){
      article[u].style.transform= `translateX(${index}%)`;
      if (document.getElementsByClassName('b-timeline_active')[0]) document.getElementsByClassName('b-timeline_active')[0].classList.remove('b-timeline_active');
      e.target.parentNode.classList.add('b-timeline_active');
    }
  }
}
