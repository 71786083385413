import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
              toggle: 'toggle',
            }
          }
    }

    init() {

    }

    toggle() {
        this.el.classList.toggle('is-open');
    }
}
